<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.ordem_servico.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.ordem_servico.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('OrdemServico', 'Inserir')"
      :sem-filtro="!buscarPermissao('OrdemServico', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('OrdemServico', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :mostrar-seletor="buscarPermissao('OrdemServico', 'Deletar')"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      selecionar-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :mostrar-select-filtro="true"
      :opcoes-select="opcoesSetores"
      :valor-padrao-select="setorPadrao"
      @paginar="listarRegistros"
      @filtroSelect="filtrarSelecionadoSelectSetor"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao text color="secondary">
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <dropdown-padrao-item
              v-if="buscarPermissao('OrdemServico', 'Editar')"
              :disabled="!!slotProps.data.dataEntrega"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('OrdemServico', 'Visualizar')"
              @click="visualizar(slotProps.data)"
            >
              {{ $t('geral.botoes.visualizar') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('OrdemServico', 'Imprimir')"
              @click="imprimir(slotProps.data)"
            >
              {{ $t('geral.botoes.imprimir') }}
            </dropdown-padrao-item>
            <dropdown-padrao-item
              v-if="buscarPermissao('OrdemServico', 'OrdemServicoParcial')"
              :disabled="
                !!slotProps.data.dataEntrega ||
                slotProps.data.quantidadeInstrumentos <= 1
              "
              @click="abrirOsParcial(slotProps.data)"
            >
              {{ $t('modulos.ordem_servico.botoes.os_parcial') }}
            </dropdown-padrao-item>
          </dropdown-padrao>
          <template>
            <div class="d-flex justify-center">
              <icone-padrao
                :icone="getSituacaoIcon(slotProps.data.situacao)"
                :tooltip="getSituacaoMessage(slotProps.data.situacao)"
              />
            </div>
          </template>
        </div>
      </template>
    </tabela-padrao-prime-vue>

    <filtro-relatorio-sql ref="visualizador" />
    <modal-visualizacao-relatorio
      ref="modal-pdf"
      :titulo="$t('modulos.ordem_servico.titulos.impressao')"
    />
  </div>
</template>
<script>
import { ModalVisualizacaoRelatorio } from '@components/misc';
import FiltroRelatorioSql from '@/views/modulos/cadastros/modelo_relatorio/components/FiltroRelatorioSql.vue';
import helpers from '@/common/utils/helpers';
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import OrdemServicoService from '@/common/services/cadastros/OrdemServicoService.js';
import Filtro from './filtro';
import SetorService from '@common/services/cadastros/SetorService';
import { mapGetters } from 'vuex';
import { enumOperacaoSituacaoIcons } from '@enums/icons';

export default {
  components: {
    FiltroRelatorioSql,
    CabecalhoPagina,
    Filtro,
    ModalVisualizacaoRelatorio,
  },
  data() {
    return {
      opcoes: {
        situacao: helpers.SituacaoOrdemServicoEnum,
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.ordem_servico.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'dataRecebimento',
            text: this.$t('modulos.ordem_servico.tabela.data_recebimento'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'participante.nome',
            text: this.$t('modulos.ordem_servico.tabela.cliente_participante'),
            sortable: true,
          },
          {
            value: 'participanteContato.nome',
            text: this.$t('modulos.ordem_servico.tabela.cliente_contato'),
            sortable: false,
          },
          {
            value: 'setoresTexto',
            text: this.$t('modulos.ordem_servico.tabela.setores'),
            sortable: true,
          },
          {
            value: 'dataPrevisaoEntrega',
            text: this.$t('modulos.ordem_servico.tabela.previsao_entrega'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'prioridade.nome',
            text: this.$t('modulos.ordem_servico.tabela.prioridade'),
            sortable: true,
            width: 150,
          },
          {
            value: 'participanteSolicitante.nome',
            text: this.$t(
              'modulos.ordem_servico.tabela.participante_solicitante'
            ),
            sortable: true,
          },
          {
            value: 'localExecucao.nome',
            text: this.$t('modulos.ordem_servico.tabela.local_execucao'),
            sortable: true,
          },
          {
            value: 'dataEntrega',
            text: this.$t('modulos.ordem_servico.tabela.entrega'),
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumSituacaoOrdemServico: [],
      opcoesSetores: [],
      setorId: null,
      setorPadrao: null,
      colunaOrdenada: '',
    };
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'OrdemServico', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir() {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.colunaOrdenada = '-createdAt,-sequencia';
    this.setorId = localStorage.getItem('usuarioSetorId');
    this.buscaSetoresParticipante();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.ordem_servico.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    imprimir: function (item) {
      this.$refs['modal-pdf'].abrirModal('ordem-servico', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    filtrarSelecionadoSelectSetor(setor) {
      this.setorId = setor?.code;
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        sort: this.colunaOrdenada,
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        setorId: this.setorId,
      };
      if (!this.setorId || this.setorId == 'null') delete parametros.setorId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      OrdemServicoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscaSetoresParticipante() {
      let params = {
        page: 1,
        amountPerPage: 100,
        participanteId: localStorage.getItem('participanteLogadoId'),
      };
      SetorService.listar(params)
        .then((res) => {
          if (res.data.items.length) {
            res.data.items.forEach((item) => {
              this.opcoesSetores.push({
                name: item.nome,
                code: item.id,
              });
            });
          }
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.setor.erros.id_invalido'));
        })
        .finally(() => {
          this.listarRegistros();
          this.setorPadrao = {};
          this.opcoesSetores.forEach((item) => {
            if (item.code == this.setorId) {
              this.setorPadrao = item;
            }
          });
        });
    },
    async excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(OrdemServicoService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.ordem_servico.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo() {
      this.$router.push({ name: 'ordem-servico-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'ordem-servico-editar',
        params: { id: item.id },
      });
    },
    visualizar(item) {
      this.$router.push({
        name: 'ordem-servico-visualizar',
        params: { id: item.id },
      });
    },
    // imprimir: function (item) {
    //   //COD-RELATORIO (Mudar o código quando tiver sido implementado uma solução na próxima sprint)
    //   this.$refs.visualizador.buscarRelatorioPorCodigo(2023, item.id);
    // },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    abrirOsParcial(item) {
      this.$router.push({
        name: 'ordem-servico-parcial',
        params: { id: item.id },
      });
    },
    getSituacaoIcon(situacao) {
      if (situacao) {
        const icon = enumOperacaoSituacaoIcons.find(
          (el) => el.value === situacao
        ).icon;

        return this.$vuetify.icons.values[icon];
      }
    },
    getSituacaoMessage(situacao) {
      if (situacao) {
        return enumOperacaoSituacaoIcons.find((el) => el.value === situacao)
          .message;
      }
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
