<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.ordem_servico.filtro.codigo')"
      :placeholder="$t('modulos.ordem_servico.filtro.codigo')"
      em-filtro
    />  
    <input-text
      v-model="filtro.nomeCliente"
      class="col-12 col-md-6"
      :label="$t('modulos.ordem_servico.filtro.cliente')"
      :placeholder="$t('modulos.ordem_servico.filtro.cliente')"
      em-filtro
    />
    <input-date
      v-model="dataRecebimento"
      :label="$t('modulos.ordem_servico.filtro.data_recebimento')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
    />
    <input-select
      v-model="filtro.situacao"
      :options="opcoes.EnumSituacaoOrdemServico"
      :label="$t('modulos.ordem_servico.filtro.situação')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
    <input-select
      v-model="filtro.prioridadeId"
      :options="opcoes.prioridade"
      :label="$t('modulos.ordem_servico.filtro.prioridade')"
      :placeholder="$t('geral.inputs.selecione')"
      class="col-12 col-md-6"
      em-filtro
      retornar-objeto
    />
    <input-text
      v-model="filtro.nomeSolicitante"
      class="col-12 col-md-6"
      :label="$t('modulos.ordem_servico.filtro.solicitante')"
      :placeholder="$t('modulos.ordem_servico.filtro.solicitante')"
      em-filtro
    />
  </div>
</template>
<script>
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import PrioridadeService from '@common/services/cadastros/PrioridadeService';

export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      dataRecebimento: '',
      opcoes: {
        localExecucao: [],
        embalagens: [],
        EnumSituacaoOrdemServico: [],
        prioridade: [],
        contato: [],
      },
    };
  },
  watch: {
    dataRecebimento: function () {
      this.filtro.dataRecebimento = this.dataRecebimento?.substr(0, 10);
    },
  },
  async mounted() {
    await this.buscarEnumSituacaoOrdemServico();
    await this.buscarPrioridade();
  },
  methods: {
    buscarEnumSituacaoOrdemServico: async function () {
      await EnumeradorService.buscar('EnumSituacaoOrdemServico').then((res) => {
        this.opcoes.EnumSituacaoOrdemServico = res;
      });
    },
    buscarPrioridade: async function () {
      await PrioridadeService.listar().then((res) => {
        this.opcoes.prioridade = new DropdownModel(res.data.items);
      });
    },
  },
};
</script>
